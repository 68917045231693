<script setup lang="ts">
import type { MonthStart } from '@console/services/api.models';
import type { AwsComputeSavingsDashboardResponse } from '@console/services/aws/savings.models';

import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';

import { useVuexStore } from '@console/state/vuex/store';
import { singleParamValue } from '@shared/utilities/routing';

import Savings from './Savings.vue';
import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';

const store = useVuexStore();
const router = useRouter();

const selectedTimeframe = ref<MonthStart>();
const savingsDashboardNotFound = ref(false);

const savings = computed<AwsComputeSavingsDashboardResponse | undefined>(() => store.state.aws.savings);
const isLoading = computed(() => !selectedTimeframe.value && !savingsDashboardNotFound.value);

onBeforeRouteUpdate(async (to, _, next) => {
  await load(singleParamValue(to.params, 'timeframe'));
  next();
});

onMounted(async () => {
  await load(singleParamValue(router.currentRoute.value.params, 'timeframe'));
});

const savingsTimeframes = computed(() => store.getters['aws/savingsTimeframes']);

const loadSavings = (timeframe?: string) => store.dispatch('aws/loadSavings', timeframe);

const load = async (timeframe: string) => {
  try {
    if (timeframe) {
      await loadSavings(timeframe);
      const filter = { key: timeframe };
      selectedTimeframe.value = _.find(savingsTimeframes.value, filter);
    } else {
      await loadSavings();
      selectedTimeframe.value = _.head(savingsTimeframes.value);
    }
  } catch (e) {
    handleError(e, timeframe);
  }
};

const handleError = async (e: unknown, timeframe: string) => {
  const status = _.get(e, 'response.status', 500) as number | undefined;
  if (status === 404) {
    if (!timeframe) {
      savingsDashboardNotFound.value = true;
    } else {
      await router.push({ name: '404' });
    }
  } else {
    throw e;
  }
};
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="savingsDashboardNotFound" />
      <Savings
        v-else-if="savings && selectedTimeframe"
        :savings="savings"
        :selected-timeframe="selectedTimeframe"
        :savings-dashboard-not-found="savingsDashboardNotFound"
      />
    </template>
  </Layout>
</template>
