<script lang="ts">
import type { AwsService, BillingOfferingVariant } from '@console/services/api.models';
import type {
  ComputeBaseFlexSpendCoverageTrend,
  ComputeInheritedBaseSmartSpendCoverageTrend,
  ComputeSpendCoverageTrend,
  NonComputeSpendCoverageTrend,
  SpendCoverageTrend,
} from '@console/services/aws/savings.models';
import type Highcharts from 'highcharts';
import type { PropType } from 'vue';

import moment from 'moment';

import * as chartUtilities from '@console/components/charts/utility';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';

const SERIES_NAME_BASE_TARGET = 'Base Target';

interface Series extends Highcharts.SeriesOptions {
  legendOrder: number;
  legendSelected: boolean;
}

type SeriesColors = Partial<Record<keyof SpendCoverageTrend, string>>;
const seriesColors: {
  compute: Partial<Record<BillingOfferingVariant, SeriesColors>>;
  nonCompute: SeriesColors;
} = {
  compute: {
    AwsComputeBaseFlex: {
      on_demand: '#fc5454',
      unbilled: '#dee2e6',
      flex: '#00c58c',
      flex_boost: '#8fffdf',
      base_convertible_reserved_instances: '#fcbe2c',
      base_standard_reserved_instances: '#a7a3ff',
      base_ec2_instance_savings_plans: '#adb5bd',
      base_compute_savings_plans: '#5c54ff',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeBaseFlexSpendCoverageTrend, string>,
    AwsComputeInheritedBaseSmart: {
      on_demand: '#fc5454',
      unbilled: '#dee2e6',
      smart_convertible_reserved_instances: '#8fffdf',
      smart_compute_savings_plans: '#00c58c',
      smart_ec2_instance_savings_plans: '#fcbe2c',
      base_compute_savings_plans: '#5c54ff',
      inherited_convertible_reserved_instances: '#fecbcb',
      inherited_standard_reserved_instances: '#adb5bd',
      inherited_compute_savings_plans: '#a7a3ff',
      inherited_ec2_instance_savings_plans: '#feebbf',
      base_spend_coverage_target: '#495057',
    } as Record<keyof ComputeInheritedBaseSmartSpendCoverageTrend, string>,
  },
  nonCompute: {
    on_demand: '#fc5454',
    smart_reserved_coverage: '#00c58c',
    inherited_reserved_coverage: '#5c54ff',
  } as Record<keyof NonComputeSpendCoverageTrend, string>,
};

export default {
  components: {
    ChartLegend,
    SeriesChart,
  },
  props: {
    spendCoverageTrend: {
      type: Array as PropType<SpendCoverageTrend[]>,
      required: true,
    },
    dashboardVariant: {
      type: String as PropType<BillingOfferingVariant>,
      required: false,
      default: undefined,
    },
    service: {
      type: String as PropType<AwsService>,
      required: true,
      default: 'compute',
    },
  },
  data() {
    return {
      series: [] as Series[],
    };
  },
  computed: {
    isProductCompute() {
      return this.service === 'compute';
    },
    xAxis() {
      return {
        categories: this.spendCoverageTrend.map(sc => moment.utc(sc.month_start).format('MMM YYYY')),
      };
    },
    yAxis() {
      return [
        {
          min: 0,
          title: {
            text: null,
          },
          labels: {
            format: chartUtilities.getCurrencyFormat(),
          },
        },
      ];
    },
    filteredSeries() {
      return this.series.filter(s => s.legendSelected);
    },
    serviceDisplayName() {
      return AwsServiceHelpers.getDisplayName(this.service);
    },
    tooltip() {
      return chartUtilities.tooltipWithTotal({
        totalLabel: 'Compute Usage',
        valueFormatter: v => NumberHelpers.formatDollars(v),
        seriesExcludedFromTotal: [SERIES_NAME_BASE_TARGET],
      });
    },
  },
  mounted() {
    const firstMonths = this.spendCoverageTrend.slice(0, -1);
    const lastMonth = this.spendCoverageTrend.slice(-1)[0] ?? {};
    // Don't include the last month if there aren't any fields other than month_start (i.e. no spend coverage data for the month)
    const includeLastMonth = Object.keys(lastMonth).some(key => key !== 'month_start');
    const months = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;

    interface SeriesDef {
      name: string;
      type: 'area' | 'line';
    }
    interface ComputeSeriesDef extends SeriesDef {
      field: keyof ComputeSpendCoverageTrend;
    }
    interface NonComputeSeriesDef extends SeriesDef {
      field: keyof NonComputeSpendCoverageTrend;
    }

    // This is the order the items will be stacked in the chart (the first will be on the top)
    const computeSeriesDefs: ComputeSeriesDef[] = [
      { name: 'On-Demand', field: 'on_demand', type: 'area' },
      { name: 'Unbilled', field: 'unbilled', type: 'area' },
      { name: 'Smart: Convertible RIs', field: 'smart_convertible_reserved_instances', type: 'area' },
      { name: 'Smart: Compute SPs', field: 'smart_compute_savings_plans', type: 'area' },
      { name: 'Smart: EC2 Instance SPs', field: 'smart_ec2_instance_savings_plans', type: 'area' },
      { name: 'Flex', field: 'flex', type: 'area' },
      { name: 'Flex Boost', field: 'flex_boost', type: 'area' },
      { name: 'Base: Convertible RIs', field: 'base_convertible_reserved_instances', type: 'area' },
      { name: 'Base: Standard RIs', field: 'base_standard_reserved_instances', type: 'area' },
      { name: 'Base: EC2 Instance SPs', field: 'base_ec2_instance_savings_plans', type: 'area' },
      { name: 'Base: Compute SPs', field: 'base_compute_savings_plans', type: 'area' },
      { name: 'Inherited: Convertible RIs', field: 'inherited_convertible_reserved_instances', type: 'area' },
      { name: 'Inherited: Standard RIs', field: 'inherited_standard_reserved_instances', type: 'area' },
      { name: 'Inherited: Compute SPs', field: 'inherited_compute_savings_plans', type: 'area' },
      { name: 'Inherited: EC2 Instance SPs', field: 'inherited_ec2_instance_savings_plans', type: 'area' },
      { name: SERIES_NAME_BASE_TARGET, field: 'base_spend_coverage_target', type: 'line' },
    ];

    // This is the order the items will be stacked in the chart (the first will be on the top)
    const nonComputeSeriesDefs: NonComputeSeriesDef[] = [
      { name: 'On-Demand', field: 'on_demand', type: 'area' },
      { name: 'Smart', field: 'smart_reserved_coverage', type: 'area' },
      { name: 'Inherited', field: 'inherited_reserved_coverage', type: 'area' },
    ];

    const seriesDefs = this.isProductCompute ? computeSeriesDefs : nonComputeSeriesDefs;

    const areaOptions = {
      fillOpacity: '0.5',
      dashStyle: 'solid',
      stacking: 'normal',
    };
    const lineOptions = {
      dashStyle: 'dash',
    };

    this.series = seriesDefs
      .map((s, index) => ({
        label: s.name,
        color: this.getSeriesColor(s.field),
        // Use null as the default value to ensure that days without values are still shown on the x axis
        data: months.map(month => month[s.field] ?? null),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
        marker: {
          enabled: false,
        },
        // Apply the options specific to the series type
        ...(s.type === 'area' ? areaOptions : lineOptions),
        // Allow the series to override any of the above properties
        ...s,
      }))
      // Don't include any series without a color (i.e. series belongs to another service or variant)
      .filter(s => !!s.color)
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d));
  },
  methods: {
    getSeriesColor(field: keyof SpendCoverageTrend) {
      const colors = this.isProductCompute
        ? seriesColors.compute[this.dashboardVariant!] ?? {}
        : seriesColors.nonCompute;

      return colors[field];
    },
  },
};
</script>

<template>
  <div>
    <ChartLegend v-model="series" />
    <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    <div class="d-flex flex-row-reverse">
      <small>(normalized)</small>
    </div>
  </div>
</template>
