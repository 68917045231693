import {
  atLeastRole,
  billingAccountOnboarded,
  hasRoles,
  notInDemo,
  or,
  requires,
  selectedCompany,
  userIsEmployee,
} from '@console/router/requirements';

import ComputeCommitments from '@gcp/views/Commitments.vue';
import ComputeSavings from '@gcp/views/ComputeSavings.vue';
import ComputeSettings from '@gcp/views/ComputeSettings.vue';
import GoogleCloudMarketplace from '@gcp/views/GoogleCloudMarketplace.vue';
import Onboarding from '@gcp/views/Onboarding.vue';

export const routes = [
  {
    path: '/google_cloud/compute/savings/:timeframe?',
    name: 'google_cloud_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      atLeastRole('Viewer'),
      or(userIsEmployee, billingAccountOnboarded)
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/google_cloud/compute/commitments',
    name: 'google_cloud_compute_commitments',
    component: ComputeCommitments,
    props: true,
    beforeEnter: requires(hasRoles, selectedCompany, billingAccountOnboarded, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/google_cloud/compute/settings',
    name: 'google_cloud_compute_settings',
    component: ComputeSettings,
    beforeEnter: requires(hasRoles, selectedCompany, billingAccountOnboarded, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/google_cloud/onboarding',
    name: 'google_cloud_onboarding',
    component: Onboarding,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Viewer')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/google_cloud_marketplace',
    name: 'google_cloud_marketplace',
    component: GoogleCloudMarketplace,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole('Owner')),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/gcp/compute/savings/:timeframe?',
    redirect: { name: `google_cloud_compute_savings` },
  },
  {
    path: `/gcp/compute/commitments`,
    redirect: { name: `google_cloud_compute_commitments` },
  },
  {
    path: `/gcp/compute/settings`,
    redirect: { name: `google_cloud_compute_settings` },
  },
  {
    path: `/gcp/onboarding`,
    redirect: { name: `google_cloud_onboarding` },
  },
];
