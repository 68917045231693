<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import { mapState } from 'pinia';
import { mapGetters as vuexMapGetters, mapState as vuexMapState, mapActions as vuexMapActions } from 'vuex';

import customerService from '@console/services/customerService';
import { useAuthStore } from '@shared/state/auth.store';

import StripeCardWidget from '@console/components/widgets/StripeCardWidget.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import TextInput from '@shared/design/TextInput.vue';

const requiredIfUnitedStates = (value, vm) => {
  if (vm.addressCountry === 'US' && !value) {
    return false;
  }
  return true;
};

const requiredIfCompanyNeedsPaymentMethod = (value, vm) => {
  if (vm.requiresPaymentMethod && !value) {
    return false;
  }
  return true;
};

const requiredIfNotHideOnlineTerms = (value, vm) => {
  if (!vm.hideOnlineTerms && !value) {
    return false;
  }
  return true;
};

const requiredToBeFalsy = value => !value;

const requiredStripePaymentDetails = (value, vm) => {
  const noStripeValidationErrors = value === null;

  if (vm.requiresPaymentMethod && !vm.enteredAllPaymentDetails && noStripeValidationErrors) {
    return false;
  }
  return true;
};

export default {
  components: {
    BoxMessage,
    TextInput,
    StripeCardWidget,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      loading: false,
      submitting: false,
      acceptTerms: false,
      requiresPaymentMethod: false,
      hideOnlineTerms: false,
      countries: null,
      states: null,
      cardholderName: null,
      cardError: null,
      addressLine1: null,
      addressLine2: null,
      addressCity: null,
      addressState: '',
      addressZip: null,
      addressCountry: '',
      enteredAllPaymentDetails: false,
    };
  },
  computed: {
    ...vuexMapState('customer', ['selectedCompanyId']),
    ...mapState(useAuthStore, ['role']),
    ...vuexMapGetters('customer', ['selectedCompany']),
    ...vuexMapGetters('nav', ['isAwsContext', 'isAzureContext', 'isGcpContext']),
    currentUserIsOwner() {
      return this.role(this.selectedCompanyId) === 'Owner';
    },
  },
  async mounted() {
    this.requiresPaymentMethod =
      _.get(this.selectedCompany, 'net_terms_days', -1) === -1 &&
      _.get(this.selectedCompany, 'aws_marketplace_customer_id', null) === null &&
      _.get(this.selectedCompany, 'google_cloud_marketplace_consumer_id', null) === null;
    this.hideOnlineTerms = _.get(this.selectedCompany, 'hide_online_terms', false);
    const [countries, states] = await Promise.all([
      customerService.getCountries().then(res => res.data),
      customerService.getStates().then(res => res.data),
    ]);
    this.countries = countries;
    this.states = states;
  },
  methods: {
    ...vuexMapActions('customer', ['subscribe']),
    async submit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.submitting = true;
      const companyAddress = _.pick(this, [
        'addressLine1',
        'addressLine2',
        'addressCity',
        'addressState',
        'addressZip',
        'addressCountry',
      ]);
      if (this.requiresPaymentMethod) {
        const result = await this.$refs.stripe.submit();
        const tokenId = _.get(result, 'token.id', null);

        if (tokenId !== null) {
          const cardholderName = this.cardholderName;
          await this.subscribe({ tokenId, cardholderName, companyAddress });
        }
      } else {
        await this.subscribe({ companyAddress });
      }
      this.submitting = false;
    },
    onCardError(error) {
      this.cardError = error;
    },
    onCardChange() {
      this.$nextTick(() => {
        const cardElementContainer = document.querySelector('#payment');
        const hasCompletedPaymentDetails = cardElementContainer.classList.contains('StripeElement--complete');

        this.enteredAllPaymentDetails = hasCompletedPaymentDetails;
      });
    },
  },
  validations: {
    cardholderName: {
      requiredIfCompanyNeedsPaymentMethod,
    },
    cardError: {
      requiredToBeFalsy,
      requiredStripePaymentDetails,
    },
    addressLine1: { required },
    addressCity: { required },
    addressCountry: { required },
    addressState: { requiredIfUnitedStates },
    addressZip: { requiredIfUnitedStates },
    acceptTerms: { requiredIfNotHideOnlineTerms },
  },
};
</script>

<template>
  <form @submit.prevent="submit">
    <BoxMessage v-if="!currentUserIsOwner" type="error" class="pt-2 pb-2">
      <strong> You do not have the necessary permissions to complete the ProsperOps subscription </strong>
      <p class="mb-0">
        In order to subscribe, you must be an
        <strong>Owner</strong> role user. Please contact your account's administrator for further assistance.
      </p>
    </BoxMessage>
    <BoxMessage v-else-if="isAwsContext" type="success" class="pt-4 mb-2">
      <strong>ProsperOps access to your AWS Organization has been confirmed!</strong>
      <p class="mb-0">
        We're now actively monitoring your usage and commitments. After subscribing, you'll receive your first invoice
        at the beginning of next month and subsequent invoices each month that follows.
      </p>
    </BoxMessage>
    <BoxMessage v-else-if="isGcpContext" type="success" class="pt-4 mb-2">
      <strong>ProsperOps access to your Google Cloud environment has been confirmed!</strong>
      <p class="mb-0">
        We're now actively monitoring your Compute usage and commitments. After subscribing, you'll receive your first
        invoice at the beginning of next month and subsequent invoices each month that follows.
      </p>
    </BoxMessage>
    <BoxMessage v-else-if="isAzureContext" type="success" class="pt-4 mb-2">
      <strong>ProsperOps access to your Azure environment has been confirmed!</strong>
      <p class="mb-0">
        We're now actively monitoring your Compute usage and commitments. After subscribing, you'll receive your first
        invoice at the beginning of next month and subsequent invoices each month that follows.
      </p>
    </BoxMessage>
    <div v-if="currentUserIsOwner" class="row pt-5">
      <div class="col-sm-6">
        <h2 class="mb-0">Company Address</h2>
        <div class="row pt-4">
          <div class="col">
            <label for="addressLine1" class="d-block required text-muted"> Address </label>
            <TextInput id="addressLine1" v-model="addressLine1" class="w-100" placeholder="Required" />
          </div>
        </div>
        <div class="row pt-4">
          <div class="col">
            <label for="addressLine2" class="d-block text-muted"> Address Line 2 </label>
            <TextInput id="addressLine2" v-model="addressLine2" class="w-100" placeholder="Optional" />
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-md-6">
            <label for="country" class="d-block required"> Country </label>
            <div class="selectWrapper">
              <select
                id="country"
                v-model="addressCountry"
                class="form-control rounded-sm selector custom-select"
                :disabled="!countries"
              >
                <option disabled value="">Select a Country</option>
                <option v-for="country in countries" :key="country.name" :value="country.two_letter_code">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <label for="city" class="d-block required text-muted"> City </label>
            <TextInput id="city" v-model="addressCity" class="w-100" placeholder="Required" />
          </div>
        </div>
        <div v-if="addressCountry === 'US' || addressCountry === '' || loading" class="row pt-4">
          <div class="col-md-6">
            <label for="state" class="d-block required text-muted"> State/Province </label>
            <div class="selectWrapper">
              <select
                id="state"
                v-model="addressState"
                class="form-control rounded-0 selector custom-select"
                :disabled="!states"
              >
                <option disabled value="">Select a State/Province</option>
                <option v-for="state in states" :key="state.name" :value="state.two_letter_code">
                  {{ state.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <label for="zip" class="d-block required text-muted"> Zip Code </label>
            <TextInput id="zip" v-model="addressZip" class="w-100" placeholder="Required" />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div v-if="requiresPaymentMethod">
          <div class="row">
            <div class="col">
              <h2 class="mb-0">Payment Details</h2>
              <div class="pt-4">
                <label for="cardholderName" class="d-block required text-muted"> Name on Card </label>
                <TextInput id="cardholderName" v-model="cardholderName" placeholder="Required" class="w-100" />
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col">
              <StripeCardWidget
                ref="stripe"
                :cardholder-name="cardholderName"
                @on-error="onCardError"
                @on-change="onCardChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentUserIsOwner" class="row pt-4">
      <div
        class="col d-flex align-items-center justify-content-between"
        :class="{ 'col-sm-6': !requiresPaymentMethod }"
      >
        <div class="form-check pointerHover mr-4">
          <template v-if="!hideOnlineTerms">
            <input
              id="acceptTerms"
              v-model="acceptTerms"
              class="form-check-input pointerHover"
              type="checkbox"
              value=""
            />
            <label class="form-check-label pointerHover text-muted" for="acceptTerms">
              I acknowledge that I have read and accept the
              <a href="https://www.prosperops.com/legal/service-terms/" target="_blank">ProsperOps Service Terms</a>.
            </label>
          </template>
        </div>
        <div>
          <form-submit-button
            variant="primary"
            type="submit"
            :disabled="v$.$invalid"
            :loading="submitting"
            class="ml-4 rounded-sm"
          >
            Subscribe
            <template v-slot:loading> Subscribing... </template>
          </form-submit-button>
        </div>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.check {
  margin-right: 0.5rem;
  color: map-get($theme-colors, 'success');
}

.selector {
  height: 36px;
  background-color: #fff;
  border: 0;
  -webkit-appearance: none;
}

.selector:focus {
  box-shadow: none;
}

label.required::before {
  display: inline-block;
  font-weight: 800;
  color: map-get($theme-colors, 'danger');
  content: '*';
}

.pointerHover:hover {
  cursor: pointer;
}

.selectWrapper::after {
  display: block;
  height: 3px;
  content: '';
}

.selectWrapper:focus-within::after {
  background-color: map-get($theme-colors, 'success');
  transition: background-color 0.2s ease-in-out;
}
</style>
