<script setup lang="ts">
import { useHead } from '@unhead/vue';

import BuildingState from '@console/components/BuildingState.vue';
import Layout from '@console/Layout.vue';

useHead({
  title: 'Azure Compute Savings',
});
</script>

<template>
  <Layout>
    <template #default>
      <BuildingState />
    </template>
  </Layout>
</template>
