<script setup lang="ts">
import type { AwsService, BillingOfferingVariant } from '@console/services/api.models';
import type { SavingsSpendCoverageBreakdown } from '@console/services/aws/savings.models';
import type { PropType } from 'vue';

import _ from 'lodash';
import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import NumberHelpers from '@shared/utilities/number_helpers';

import SectionChart, { type Section, type Thumb } from '@console/components/charts/SectionChart.vue';
import Currency from '@shared/components/Currency.vue';
import Percentage from '@shared/components/Percentage.vue';

const props = defineProps({
  spendCoverage: {
    type: Object as PropType<SavingsSpendCoverageBreakdown>,
    required: true,
  },
  dashboardVariant: {
    type: String as PropType<BillingOfferingVariant>,
    required: false,
    default: undefined,
  },
  finalized: {
    type: Boolean,
    required: true,
  },
  service: {
    type: String as PropType<AwsService>,
    required: true,
    default: 'compute',
  },
});

type SpendCoverageField = 'unbilled' | 'base' | 'inherited' | 'flex' | 'flex_boost' | 'smart'
type SpendCoverageDefinition = { name: string; field: SpendCoverageField };
const spendConverageDefs: SpendCoverageDefinition[] = [
  { name: 'Base', field: 'base' },
  { name: 'Flex', field: 'flex' },
  { name: 'Flex Boost', field: 'flex_boost' },
  { name: 'Smart', field: 'smart' },
  { name: 'Inherited', field: 'inherited' },
  { name: 'Unbilled', field: 'unbilled' },
];

type SeriesColors = Partial<Record<SpendCoverageField, string>>;
const seriesColors: {
  compute: Partial<Record<BillingOfferingVariant, SeriesColors>>;
  nonCompute: SeriesColors;
} = {
  compute: {
    'AwsComputeBaseFlex': {
      base: '#a7a3ff',
      flex: '#5c54ff',
      flex_boost: '#05004d',
      unbilled: '#dee2e6',
    },
    'AwsComputeInheritedBaseSmart': {
      base: '#a7a3ff',
      smart: '#5c54ff',
      inherited: '#05004d',
      unbilled: '#dee2e6',
    },
  },
  nonCompute: {
    smart: '#00c58c',
    inherited: '#5c54ff',
  },
};

const store = useVuexStore();
const isMultiOrgSummarySelected = computed(() => store.getters['aws/isMultiOrgSummarySelected']);
const isReseller = computed(() => store.getters['customer/isReseller']);

const isProductCompute = computed(() => props.service === 'compute');

const spendCoverages = computed(() =>
  spendConverageDefs
    .map(s => ({
      label: `${s.name} Spend Coverage`,
      color: getSeriesColor(s.field),
      amount: props.spendCoverage[`${s.field}_spend_coverage`] ?? 0,
      percent: props.spendCoverage[`${s.field}_spend_coverage_percentage`] ?? 0,
    }))
    // Don't include any series without a color (i.e. series belongs to another service, this shouldn't happen)
    .filter(s => !!s.color)
    // Exclude any that don't have any amount
    .filter(s => s.amount > 0)
);

function getSeriesColor(field: SpendCoverageField): string | undefined {
  const colors = isProductCompute.value
    ? seriesColors.compute[props.dashboardVariant!] ?? {}
    : seriesColors.nonCompute;

  return colors[field];
}

const sections = computed<Section[]>(() => spendCoverages.value
  .map(sc => ({
    key: sc.label,
    color: sc.color,
    value: sc.percent,
  } as Section))
  .concat([{
    key: 'On-Demand',
    color: '#f5f6fa',
    value: _.round(100 - (props.spendCoverage.overall_spend_coverage_percentage ?? 0), 2),
    showTooltip: false,
  }])
  .filter(s => !!s.value)
);

const total = computed(() => {
  if (props.spendCoverage.overall_spend_coverage && props.spendCoverage.overall_spend_coverage_percentage) {
    return (props.spendCoverage.overall_spend_coverage / props.spendCoverage.overall_spend_coverage_percentage) * 100;
  }
  return undefined;
});

const thumb = computed<Thumb>(() => {
  // Non-compute doesn't have a base target
  if (!isProductCompute.value) {
    return { visible: false };
  }

  const target = props.spendCoverage.base_target ?? 0;
  const position = !total.value ? 0 : (target / total.value) * 100;
  return {
    visible: !!total.value && props.finalized && !isMultiOrgSummarySelected.value,
    position: position,
    description: `Base Target: ${NumberHelpers.formatDollars(target, 0)}`,
  };
});

const overallCoverageHeading = computed(() =>
  isReseller.value && isProductCompute.value ? 'Arbitrage Spend Coverage' : 'Overall Spend Coverage'
);
</script>

<template>
  <div class="spendCoverageBreakdown">
    <div class="d-flex justify-content-center pt-5">
      <SectionChart :sections="sections" :thumb="thumb" />
    </div>
    <div class="tableWrapper">
      <table class="table mt-5">
        <tr v-for="sc in spendCoverages" :key="sc.label">
          <th>
            <div class="base usageType" :style="`background-color: ${sc.color}`"></div>
            {{ sc.label }}
          </th>
          <td>
            <Currency :value="sc.amount" />
          </td>
          <td>
            <Percentage :value="sc.percent" />
          </td>
        </tr>
        <tr class="footer">
          <th>{{ overallCoverageHeading }}</th>
          <td>
            <Currency :value="spendCoverage.overall_spend_coverage" />
          </td>
          <td>
            <Percentage :value="spendCoverage.overall_spend_coverage_percentage" />
          </td>
        </tr>
      </table>
    </div>
    <div class="d-flex flex-row-reverse">
      <small>(normalized)</small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.spendCoverageBreakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.spendCoverageBreakdown > div:nth-child(1),
.spendCoverageBreakdown > div:nth-child(2) {
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  @include media-breakpoint-up(lg) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

.tableWrapper {
  flex-grow: 1;
  padding: 0;
  padding-top: 0.5rem;
  margin: 0;
  th,
  td {
    border: 0;
  }
  .footer {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }
}

.tableWrapper tr td {
  padding-right: 0;
  text-align: right;
}

.tableWrapper tr th {
  padding-left: 0;
}

.tableWrapper tr th,
.tableWrapper tr td {
  // Default the padding and font-with
  padding-top: 1rem;
  font-weight: 400;
}

.tableWrapper tr:nth-last-child(2) th,
.tableWrapper tr:nth-last-child(2) td {
  // Increase the bottom padding for the second to last row (before the footer)
  padding-bottom: 1.5rem;
}

.tableWrapper tr:last-child th,
.tableWrapper tr:last-child td {
  padding-top: 1.5rem;

  // Bold the text and increase the top padding for the footer row
  font-weight: 600;
}

.usageType {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-bottom: 1px;
  border-radius: 50%;
}
</style>
