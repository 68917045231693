<script setup lang="ts">
import type {
  NonComputeCommitmentsDashboardResponse,
  RegionResponse,
  FilteredNonComputeCommitmentsDashboard,
  AwsOrganizationTotalRemainingCommitment,
  AwsOrganizationNonComputeCommitmentBurndown,
  NonComputeService,
} from '@console/services/api.models';
import type {
  AggregationChangeEvent,
  AmortizationChangeEvent,
  PanelDropdownChangeEvent,
} from '@shared/design/panels/types';
import type { PropType } from 'vue';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import { computed, reactive, onMounted, ref, watch } from 'vue';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import CommitmentLockInRisk from '@aws/components/commitments/CommitmentLockInRisk.vue';
import CommitmentLockInRiskDetail from '@aws/components/commitments/CommitmentLockInRiskDetail.vue';
import CommitmentLockInRiskTrend from '@aws/components/commitments/CommitmentLockInRiskTrend.vue';
import CommitmentBurndown from '@aws/components/commitments/non-compute/CommitmentBurndown.vue';
import TotalRemainingCommitment from '@aws/components/commitments/non-compute/TotalRemainingCommitment.vue';
import WeightedAverageDurationTrend from '@aws/components/commitments/WeightedAverageDurationTrend.vue';
import RegionFilter from '@aws/components/filters/RegionFilter.vue';
import BuildingState from '@console/components/BuildingState.vue';
import DropdownFilter from '@console/components/filters/DropdownFilter.vue';
import Layout from '@console/Layout.vue';
import CommitmentAsOfPill from '@shared/components/CommitmentAsOfPill.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import AggregationDropdown from '@shared/design/panels/AggregationDropdown.vue';
import AmortizationDropdown from '@shared/design/panels/AmortizationDropdown.vue';
import PanelDropdown from '@shared/design/panels/PanelDropdown.vue';
import PanelLabel from '@shared/design/panels/PanelLabel.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps({
  service: {
    type: String as PropType<NonComputeService>,
    required: true,
    validator: AwsServiceHelpers.isValidNonComputeService,
  },
});

const serviceName = computed(() => {
  return AwsServiceHelpers.getDisplayName(props.service);
});

useHead({
  title: `${serviceName.value} Commitments`,
});

const featureStore = useFeatureStore();
const showWadDropdown = computed(() => featureStore.featureFlags.awsClrWadDropdown);

const store = useVuexStore();
const selectedOrganization = computed(() => {
  return store.getters['aws/selectedOrganization'];
});

const commitments = ref<NonComputeCommitmentsDashboardResponse>();
const commitmentsNotFound = ref(false);

onMounted(async () => {
  try {
    const awsOrganizationId = selectedOrganization.value.id;
    const response = await customerService.getNonComputeCommitments(awsOrganizationId, props.service);
    commitments.value = response?.data;
  } catch (e: unknown) {
    if (isAxiosError(e) && e.response?.status === 404) {
      commitmentsNotFound.value = true;
    } else {
      throw e;
    }
  }
});

const isLoading = computed(() => {
  return !commitments.value && !commitmentsNotFound.value;
});

const emptyCommitments = computed(() => {
  return !commitments.value?.dashboard.region_filters.length;
});

const dashboard = computed(() => {
  return commitments.value?.dashboard;
});

// Region Filters
const regionFilters = computed(() => {
  return commitments.value?.dashboard.region_filters || [];
});

const regionOptions = computed(() => {
  return regionFilters.value
    .map(regionFilter => regionFilter.region)
    .sort((a, b) => a.display_name.localeCompare(b.display_name));
});

const selectedRegionOption = ref<RegionResponse>();
watch(selectedRegionOption, () => {
  selectedMatchKey.value = null;
});

const selectedRegion = computed(() => {
  return selectedRegionOption.value?.system_name || 'global';
});

const selectedRegionFilter = computed(() => {
  return regionFilters.value.find(regionFilter => regionFilter.region.system_name === selectedRegion?.value);
});

// Match key filters
const globalMatchKeys = computed(() => {
  var uniqueMatchKeys = new Set<string>();
  regionFilters.value?.forEach(filter => filter.match_keys.forEach(mk => uniqueMatchKeys.add(mk)));
  return [...uniqueMatchKeys].sort();
});

const matchKeyOptions = computed(() => {
  return selectedRegionFilter.value?.match_keys || globalMatchKeys.value;
});

const selectedMatchKey = ref();
watch(selectedMatchKey, () => {
  pivots.clr_detail = selectedMatchKey.value ? 'Weighted Average Duration' : 'Commitment Lock-In Risk';
});

const filterKey = computed(() => {
  return selectedMatchKey.value || selectedRegion.value;
});

type ClrPivots = 'Commitment Lock-In Risk' | 'Weighted Average Duration';

// Chart facets and pivots
const { facets, pivots } = reactive({
  facets: {
    commitment_burndown: 'amortized',
    total_remaining_commitment: 'amortized',
  },
  pivots: {
    commitment_burndown: 'daily',
    clr_trend: 'Commitment Lock-In Risk',
    clr_detail: 'Commitment Lock-In Risk',
  },
});

type FacetKeys = keyof typeof facets;
type PivotKeys = keyof typeof pivots;

const facetChange = (event: AmortizationChangeEvent) => {
  facets[event.name as FacetKeys] = event.selected;
};

const pivotChange = (
  event:
    | AggregationChangeEvent
    | PanelDropdownChangeEvent<'trailing 12 months' | 'daily'>
    | PanelDropdownChangeEvent<'Commitment Lock-In Risk' | 'Weighted Average Duration'>
) => {
  pivots[event.name as PivotKeys] = event.selected;
};

const burndownKey = computed(() => {
  const facet = facets.commitment_burndown;
  const pivot = pivots.commitment_burndown;
  return `${facet}_${pivot}_commitment_burndown` as keyof FilteredNonComputeCommitmentsDashboard;
});

const totalRemainingKey = computed(() => {
  const facet = facets.total_remaining_commitment;
  return `${facet}_total_remaining_commitment` as keyof FilteredNonComputeCommitmentsDashboard;
});

const baselineCommitmentLockInRisk = computed(() => {
  return filterKey.value === 'global' ? dashboard.value?.baseline_commitment_lock_in_risk : null;
});

// Visible Charts
const filteredCharts = computed(() => {
  const filteredDashboard = dashboard.value?.filtered_dashboards[filterKey.value];
  if (!filteredDashboard) return;

  return {
    dataThroughDate: dashboard.value?.data_through_date,
    burndown: filteredDashboard[burndownKey.value] as AwsOrganizationNonComputeCommitmentBurndown,
    totalRemaining: filteredDashboard[totalRemainingKey.value] as AwsOrganizationTotalRemainingCommitment,
    commitmentLockInRiskDetail: filteredDashboard.commitment_lock_in_risk_detail,
    weightedAverageDurationDetail: filteredDashboard.weighted_average_duration_detail,
    commitmentLockInRisk: filteredDashboard.current_commitment_lock_in_risk,
    weightedAverageDurationTrend: filteredDashboard.weighted_average_duration_trend,
    commitmentLockInRiskTrailingTrend: filteredDashboard.commitment_lock_in_risk_trailing_trend,
    commitmentLockInRiskTrend: filteredDashboard.commitment_lock_in_risk_trend,
  };
});

const showWadDetail = computed(() => {
  if (showWadDropdown.value) {
    return pivots.clr_detail === 'Weighted Average Duration';
  }

  return !!selectedMatchKey.value;
});

const clrDetailTitle = computed(() =>
  selectedMatchKey.value ? 'Weighted Average Duration Detail' : 'Commitment Lock-In Risk Detail'
);

const clrDetailMax = computed(() => {
  if (!showWadDropdown.value || !filteredCharts.value) return null;
  const dataMax = filteredCharts.value.commitmentLockInRiskDetail.series_data.reduce(
    (max, series) => Math.max(max, series.value),
    0
  );
  return dataMax + 2; // leave room for the label
});
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="commitmentsNotFound || emptyCommitments || !filteredCharts" />
      <div v-else>
        <PageHeader wrap-utility>
          <h1>{{ serviceName }} Commitments</h1>
          <template v-slot:utility>
            <CommitmentAsOfPill :data-through-date="filteredCharts.dataThroughDate" />
          </template>
        </PageHeader>
        <div class="d-flex flex-row flex-wrap gap-2">
          <!-- TODO: update to just v-model (remove :model-value) once we're no longer in compatibility mode.
               see: https://v3-migration.vuejs.org/breaking-changes/v-model -->
          <RegionFilter v-model:model-value="selectedRegionOption" :regions="regionOptions" />
          <DropdownFilter
            v-model:model-value="selectedMatchKey"
            label="Match Key"
            search-place-holder="Search Match Keys"
            all-keys-label="All Match Keys"
            :filter-values="matchKeyOptions"
          />
        </div>

        <div class="row sectional summary">
          <div class="col commitmentLockInRisk">
            <PanelSection header="Commitment Lock-In Risk">
              <template v-slot:utility>
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <CommitmentLockInRisk
                :key="`${filterKey}_clr`"
                :value="filteredCharts.commitmentLockInRiskDetail.overall ?? 0"
                :baseline="baselineCommitmentLockInRisk"
                variant="laddered"
                class="fadeIn"
              />
            </PanelSection>
          </div>
          <div class="col commitmentLockInRiskDetail">
            <PanelSection :header="clrDetailTitle">
              <template v-slot:utility>
                <PanelDropdown
                  v-if="showWadDropdown"
                  v-model="pivots.clr_detail as ClrPivots"
                  name="clr_detail"
                  :options="['Commitment Lock-In Risk', 'Weighted Average Duration']"
                  class="mr-1"
                  @change="pivotChange"
                />
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <CommitmentLockInRiskDetail
                :key="`${filterKey}_clr_detail`"
                :max="clrDetailMax"
                :data="
                  showWadDetail
                    ? filteredCharts.weightedAverageDurationDetail.series_data
                    : filteredCharts.commitmentLockInRiskDetail.series_data
                "
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Burndown">
              <template v-slot:utility>
                <div class="d-flex">
                  <AggregationDropdown name="commitment_burndown" class="mr-2" @change="pivotChange" />
                  <AmortizationDropdown class="fixedWidthToggle" name="commitment_burndown" @change="facetChange" />
                </div>
              </template>
              <!-- supply a key to force an unmount and remount for better transitions when changing filters/facets/pivots -->
              <CommitmentBurndown
                :key="`${filterKey}_${burndownKey}`"
                :commitment-burndown="filteredCharts.burndown"
                :data-through-date="filteredCharts.dataThroughDate"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Total Remaining Commitment">
              <template v-slot:utility>
                <AmortizationDropdown
                  class="fixedWidthToggle"
                  name="total_remaining_commitment"
                  @change="facetChange"
                />
              </template>
              <TotalRemainingCommitment
                :key="`${filterKey}_${totalRemainingKey}`"
                :total-remaining-commitment="filteredCharts.totalRemaining"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Lock-In Risk Trend">
              <template v-slot:utility>
                <PanelDropdown
                  v-if="showWadDropdown"
                  name="clr_trend"
                  :options="['Commitment Lock-In Risk', 'Weighted Average Duration']"
                  class="mr-2"
                  @change="pivotChange"
                />
                <PanelLabel>Amortized</PanelLabel>
              </template>
              <WeightedAverageDurationTrend
                v-if="showWadDropdown && pivots['clr_trend'] == 'Weighted Average Duration'"
                :trend="filteredCharts.weightedAverageDurationTrend"
                :data-through-date="filteredCharts.dataThroughDate"
              />
              <CommitmentLockInRiskTrend
                v-else
                :baseline="baselineCommitmentLockInRisk"
                :trend="filteredCharts.commitmentLockInRiskTrend"
                :data-through-date="filteredCharts.dataThroughDate"
              />
            </PanelSection>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

// Highcharts resizing via javascript breaks the layout with flexbox, the detail CLR detail loads fine but
// gets stuck on the second row when resizing up or down. Using css grid for full control over the columns/rows
$sidebar-and-padding: 375px;
$gauge-width: 430px;
$detail-min: 550px;

// account for the -15 margin on each side from the row class
$detail-offset: 15px * 2;

.row.summary {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $gauge-width + $detail-min + $sidebar-and-padding) {
    grid-template-columns: $gauge-width minmax($detail-min, 1fr);
  }
}

.commitmentLockInRisk {
  :deep(p) {
    max-width: 675px;
  }
}

.commitmentLockInRiskDetail {
  width: calc(100% - $detail-offset);
  padding: 0;
  margin-left: 15px;
  overflow: hidden;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.fadeIn {
  opacity: 1;
  animation: fade 1s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.fixedWidthToggle {
  width: 6rem;
}
</style>
