<script setup lang="ts">
import { ref } from 'vue';

import useUniqueId from '@shared/composables/useUniqueId';

// we have to generate unique ids for the gradients otherwise we get weirdness
// when trying to use the logo in multiple places
// https://prosperops.slack.com/archives/CC8HNHTB4/p1730482981787859
const componentId = useUniqueId();
const gradientId1 = ref(`azure_logo_gradient_1_${componentId}`);
const gradientId2 = ref(`azure_logo_gradient_2_${componentId}`);
const gradientId3 = ref(`azure_logo_gradient_3_${componentId}`);
</script>

<template>
  <svg :id="gradientId1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <defs>
      <linearGradient :id="gradientId2" x1="8.88" y1="12.21" x2="8.88" y2="0.21" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#0078d4" />
        <stop offset="0.82" stop-color="#5ea0ef" />
      </linearGradient>
      <linearGradient :id="gradientId3" x1="8.88" y1="16.84" x2="8.88" y2="12.21" gradientUnits="userSpaceOnUse">
        <stop offset="0.15" stop-color="#ccc" />
        <stop offset="1" stop-color="#707070" />
      </linearGradient>
    </defs>
    <title>Icon-compute-21</title>
    <rect x="-0.12" y="0.21" width="18" height="12" rx="0.6" fill="url(#f34d9569-2bd0-4002-8f16-3d01d8106cb5)" />
    <polygon points="11.88 4.46 11.88 7.95 8.88 9.71 8.88 6.21 11.88 4.46" fill="#50e6ff" />
    <polygon points="11.88 4.46 8.88 6.22 5.88 4.46 8.88 2.71 11.88 4.46" fill="#c3f1ff" />
    <polygon points="8.88 6.22 8.88 9.71 5.88 7.95 5.88 4.46 8.88 6.22" fill="#9cebff" />
    <polygon points="5.88 7.95 8.88 6.21 8.88 9.71 5.88 7.95" fill="#c3f1ff" />
    <polygon points="11.88 7.95 8.88 6.21 8.88 9.71 11.88 7.95" fill="#9cebff" />
    <path
      d="M12.49,15.84c-1.78-.28-1.85-1.56-1.85-3.63H7.11c0,2.07-.06,3.35-1.84,3.63a1,1,0,0,0-.89,1h9A1,1,0,0,0,12.49,15.84Z"
      :fill="`url(#${gradientId3})`"
    />
  </svg>
</template>
