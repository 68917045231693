<script setup lang="ts">
import { formatDate } from '@shared/utilities/date_helpers';

import DataExportSettingRow from './DataExportSettingRow.vue';
import Toggle from './Toggle.vue';
import Tooltip from '@shared/design/Tooltip.vue';

interface Props {
  lastExportDate: string | null | undefined;
  toggleValue: boolean; // Used to configure the value of the toggle button
  isDisabled: boolean; // Used to configure whether the toggle button is useable
  error: { message: string; date: string } | null;
}
const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
});

const emits = defineEmits<{ (e: 'onToggle', toggleValue: boolean): void }>();
const toggle = (enabled: boolean) => emits('onToggle', enabled);
</script>

<template>
  <DataExportSettingRow>
    <template #label> Enabled </template>
    <template #description>
      Export data to the S3 bucket specified below. Allow up to 48 hours for historical data to be transferred.
    </template>
    <template #value>
      <div class="container">
        <div class="row">
          <div class="col-2 toggle">
            <Toggle
              :enabled="toggleValue ?? false"
              off="OFF"
              on="ON"
              :disabled="isDisabled"
              @change="enabled => toggle(enabled)"
            />
          </div>
          <div class="col-1">
            <span v-if="props.error">
              <BaseIcon id="customerDataExportError" name="exclamation-triangle" class="text-danger icon" />
            </span>
            <Tooltip v-if="props.error" target="customerDataExportError" placement="bottom">
              {{ props.error.message }}
            </Tooltip>
          </div>
        </div>
        <div v-if="toggleValue && lastExportDate" class="row mt-2">
          <div>
            <span class="mr-2"><strong>Last export</strong>:</span>
            <span>
              {{ formatDate(lastExportDate) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </DataExportSettingRow>
</template>

<style lang="scss" scoped>
.toggle {
  /* stylelint-disable selector-class-pattern */ // These classes come from bootstrap
  &.col-2 {
    max-width: 10%;
  }

  padding: 0;
}
.icon {
  width: 22px;
  height: 30px;
}
</style>
