<script lang="ts" setup>
import type { EffectiveSavingsRate } from '@console/services/aws/savings.models';

import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import NumberHelpers from '@shared/utilities/number_helpers';

import SolidGaugeChart from '@console/components/charts/SolidGaugeChart.vue';
import Modal from '@shared/design/Modal.vue';

const props = defineProps<{
  data: EffectiveSavingsRate;
  preSubscriptionEsrDays?: number | null;
  defaultMax?: number;
  allDiscounts?: boolean;
}>();

const store = useVuexStore();
const isMultiOrgSummarySelected = computed(() => store.getters['aws/isMultiOrgSummarySelected']);

const current = computed(() => (props.allDiscounts ? props.data.current_all_discounts : props.data.current));
const baseline = computed(() =>
  props.allDiscounts ? props.data.prior_to_subscription_all_discounts : props.data.prior_to_subscription
);
const priorToSubscription = computed(() => NumberHelpers.formatNumber(baseline.value, 1));

const showManagementCostsNote = computed(() =>
  props.allDiscounts
    ? props.data.is_prior_to_subscription_all_discounts_overridden
    : props.data.is_prior_to_subscription_overridden
);
const modalSize = computed(() => (showManagementCostsNote.value ? 'md' : 'lg'));
</script>

<template>
  <div class="h-100">
    <Modal id="esr-before-prosperops" :size="modalSize" title="Effective Savings Rate before ProsperOps">
      <p v-if="preSubscriptionEsrDays" data-test-id="prev-esr-days">
        Your Effective Savings Rate for the {{ preSubscriptionEsrDays }} days prior to ProsperOps was:
      </p>
      <div class="pb-4 text-center display-4" data-test-id="prev-esr">
        <strong>{{ priorToSubscription }}%</strong>
      </div>
      <p v-if="!showManagementCostsNote" data-test-id="prev-esr-mgmt-costs-note">
        Note: This value assumes there were <strong>zero</strong> management costs (e.g. cloud management platform or
        other tooling/script development, people time, etc.) necessary to achieve this savings outcome. Effective
        Savings Rate after ProsperOps includes our savings share so is inclusive of management costs.
      </p>
    </Modal>
    <div class="currentEsr">
      <div>
        <SolidGaugeChart title="Savings Rate" :value="current" :default-max="defaultMax" />
        <p>
          ProsperOps algorithms optimize for Effective Savings Rate. This metric best represents overall savings
          performance and always correlates with maximum savings.
          <a href="https://www.effectivesavingsrate.com/" class="ml-2" target="_blank">Learn More</a>
        </p>
      </div>
      <div v-if="!isMultiOrgSummarySelected" class="priorESR">
        <!-- We need CUR data to capture a baseline for the All Discounts view, some customers don't have enough CUR history so we'll just hide this if it's missing  -->
        <!-- We still render the container for a better experience when toggling between commitment discounts and all discounts  -->
        <small v-if="!allDiscounts || baseline != null" class="text-center">
          <a v-b-modal.esr-before-prosperops href="#" class="text-muted" @click.prevent>
            What was my Effective Savings Rate before ProsperOps?
          </a>
        </small>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.currentEsr {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div:first-child {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    > p {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 0;
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 1.5rem;
    text-align: center;
  }

  .priorESR {
    padding: 1rem 0;
    margin: 2rem 2rem 0 2rem;
    border-top: 1px solid $gray-200;
  }
}
</style>
