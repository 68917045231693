<script setup lang="ts">
import type {
  CommitmentBurndown,
  ComputeCommitmentsDashboardResponse,
  FilteredComputeCommitmentsDashboard,
  TotalRemainingCommitment,
} from '@console/services/gcp/api.models';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import { computed, reactive, onMounted, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import * as billingAccountService from '@gcp/services/billingAccountService';

import BuildingState from '@console/components/BuildingState.vue';
import DropdownFilter from '@console/components/filters/DropdownFilter.vue';
import Layout from '@console/Layout.vue';
import CommitmentLockInRisk from '@gcp/components/commitments/CommitmentLockInRisk.vue';
import CommitmentLockInRiskDetail from '@gcp/components/commitments/CommitmentLockInRiskDetail.vue';
import CommitmentLockInRiskTrend from '@gcp/components/commitments/CommitmentLockInRiskTrend.vue';
import CommitmentBurndownChart from '@gcp/components/commitments/compute/CommitmentBurndown.vue';
import CUDExpirationTimeline from '@gcp/components/commitments/compute/CUDExpirationTimeline.vue';
import TotalRemainingCommitmentChart from '@gcp/components/commitments/compute/TotalRemainingCommitment.vue';
import CommitmentAsOfPill from '@shared/components/CommitmentAsOfPill.vue';
import InfoTooltip from '@shared/design/InfoTooltip.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import AggregationDropdown from '@shared/design/panels/AggregationDropdown.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

useHead({
  title: 'Compute Commitments',
});

const commitmentsDashboardResponse = ref<ComputeCommitmentsDashboardResponse>();
const commitmentsNotFound = ref(false);

const store = useVuexStore();

const isLoading = computed(() => !commitmentsDashboardResponse.value && !commitmentsNotFound.value);

const emptyCommitments = computed(() => {
  return !commitmentsDashboardResponse.value?.dashboard.match_keys.length;
});

const dashboard = computed(() => commitmentsDashboardResponse.value?.dashboard);

const matchKeyOptions = computed(() => {
  var uniqueMatchKeys = new Set<string>();
  dashboard.value?.match_keys?.forEach(mk => uniqueMatchKeys.add(mk));
  return [...uniqueMatchKeys].sort();
});

const projectOptions = computed(() => {
  const timelines = dashboard.value?.commited_use_discount_expiration_timelines;
  var uniqueProjectIds = new Set<string>();
  timelines?.forEach(t => uniqueProjectIds.add(t.project_id));
  return [...uniqueProjectIds].sort();
});

const isDemo = computed(() => store.getters['customer/isDemo']);

onMounted(async () => {
  try {
    const gcpBillingAccountId = store.getters['gcp/selectedBillingAccountId'];
    const response = await billingAccountService.getCommitmentsDashboard(gcpBillingAccountId);
    commitmentsDashboardResponse.value = (response as unknown) as ComputeCommitmentsDashboardResponse;
    if (commitmentsDashboardResponse.value == null) {
      commitmentsNotFound.value = true;
    }
  } catch (e) {
    handleError(e);
  }
});

function handleError(e: unknown) {
  if (isAxiosError(e) && e.status === 404) {
    commitmentsNotFound.value = true;
  } else {
    throw e;
  }
}

// Match key filters

const selectedMatchKey = ref();
const selectedProjectId = ref();

const filterKey = computed(() => {
  return selectedMatchKey.value || 'global';
});

const filterProjectId = computed(() => {
  return selectedProjectId.value || '';
});

// Chart facets and pivots

const pivots = reactive({
  commitment_burndown: 'daily',
  clr_trend: 'trailing 12 months',
});

type PanelDropdownChangeEvent<TOptions extends string> = {
  name: string;
  selected: TOptions;
};

type Aggregation = 'daily' | 'cumulative';
type AggregationChangeEvent = PanelDropdownChangeEvent<Aggregation>;

type PivotKeys = keyof typeof pivots;

const pivotChange = (event: AggregationChangeEvent | PanelDropdownChangeEvent<'trailing 12 months' | 'daily'>) => {
  pivots[event.name as PivotKeys] = event.selected;
};

const burndownKey = computed(() => {
  const pivot = pivots.commitment_burndown;
  return `${pivot}_commitment_burndown` as keyof FilteredComputeCommitmentsDashboard;
});

const clrDetailTitle = computed(() =>
  selectedMatchKey.value ? 'Weighted Average Duration Detail' : 'Commitment Lock-In Risk Detail'
);

const showWadDetail = computed(() => {
  return !!selectedMatchKey.value;
});

const showClrTrend = computed(() => {
  if (!filteredCharts.value) return false;
  return filteredCharts.value?.commitmentLockInRiskTrend?.overall?.filter(el => el !== null).length > 1;
});

const clrDetailMax = computed(() => {
  if (!filteredCharts.value) return null;

  const series = showWadDetail.value
    ? filteredCharts.value.weightedAverageDurationDetail?.series_data || []
    : filteredCharts.value.commitmentLockInRiskDetail?.series_data || [];
  const dataMax = series.reduce((max, series) => Math.max(max, series.value), 0);
  return dataMax + 2; // leave room for the label
});

// Visible Charts
const filteredCharts = computed(() => {
  const filteredDashboard = dashboard.value?.filtered_dashboards[filterKey.value];
  if (!filteredDashboard) return;

  return {
    dataThroughDate: dashboard.value?.data_through_date,
    burndown: filteredDashboard[burndownKey.value] as CommitmentBurndown,
    totalRemaining: filteredDashboard.total_remaining_commitment as TotalRemainingCommitment,
    commitmentLockInRiskDetail: filteredDashboard.commitment_lock_in_risk_detail,
    weightedAverageDurationDetail: filteredDashboard.weighted_average_duration_detail,
    commitmentLockInRiskTrend: filteredDashboard.commitment_lock_in_risk_trend,
  };
});

const filteredTimelines = computed(() => {
  let timelines = dashboard.value?.commited_use_discount_expiration_timelines;
  if (filterProjectId.value !== '') {
    timelines = timelines?.filter(t => t.project_id === filterProjectId.value);
  }
  if (selectedMatchKey.value) {
    timelines = timelines?.filter(t => t.match_key === selectedMatchKey.value);
  }
  return timelines;
});

const baselineCommitmentLockInRisk = computed(() => {
  return filterKey.value === 'global' ? dashboard.value?.baseline_commitment_lock_in_risk : null;
});

const showClrTrendTooltip = computed(() => {
  const trendData = filteredCharts.value?.commitmentLockInRiskTrend?.overall;

  // Each item in the array represents a day across a 12 month period, with the first
  // element being the day exactly one year ago (365 days) and the last element being
  // the current date.
  // Missing data for a day is represented by null.
  // So if the first element is null, it means we haven't gotten 12 months of data yet.
  return trendData?.[0] == null;
});
</script>

<template>
  <Layout :loading="isLoading">
    <template #default>
      <BuildingState v-if="commitmentsNotFound || emptyCommitments || !filteredCharts" />
      <div v-else>
        <PageHeader wrap-utility>
          <h1>Compute Commitments</h1>
          <template v-slot:utility>
            <CommitmentAsOfPill :data-through-date="filteredCharts.dataThroughDate" />
          </template>
        </PageHeader>
        <div class="d-flex flex-row flex-wrap gap-2">
          <DropdownFilter
            v-model:model-value="selectedMatchKey"
            label="Match Key"
            search-place-holder="Search Match Keys"
            :filter-values="matchKeyOptions"
            all-keys-label="Global"
          />
        </div>
        <div class="row sectional summary">
          <div class="col commitmentLockInRisk">
            <PanelSection header="Commitment Lock-In Risk">
              <CommitmentLockInRisk
                :key="`${filterKey}_clr`"
                :value="filteredCharts.commitmentLockInRiskDetail?.overall ?? 0"
                :baseline="baselineCommitmentLockInRisk"
                variant="term-optimized"
                class="fadeIn"
              />
            </PanelSection>
          </div>
          <div class="col">
            <PanelSection :header="clrDetailTitle">
              <CommitmentLockInRiskDetail
                :key="`${filterKey}_clr_detail`"
                :max="clrDetailMax"
                :data="
                  showWadDetail
                    ? filteredCharts.weightedAverageDurationDetail?.series_data || []
                    : filteredCharts.commitmentLockInRiskDetail?.series_data || []
                "
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Burndown">
              <template v-slot:utility>
                <div class="d-flex">
                  <AggregationDropdown name="commitment_burndown" class="mr-2" @change="pivotChange" />
                </div>
              </template>
              <!-- supply a key to force an unmount and remount for better transitions when changing filters/facets/pivots -->
              <CommitmentBurndownChart
                :key="`${filterKey}_${burndownKey}`"
                :commitment-burndown="filteredCharts.burndown"
                :data-through-date="filteredCharts.dataThroughDate"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>

        <div class="row sectional">
          <div class="col">
            <PanelSection header="Total Remaining Commitment">
              <template v-slot:utility>
                <AmortizationDropdown class="fixedWidthToggle" name="total_remaining_commitment" />
              </template>
              <TotalRemainingCommitmentChart
                :key="`${filterKey}_total_remaining_commitment`"
                :total-remaining-commitment="filteredCharts.totalRemaining"
                class="fadeIn"
              />
            </PanelSection>
          </div>
        </div>
        <div v-if="showClrTrend" class="row sectional">
          <div class="col">
            <PanelSection header="Commitment Lock-In Risk Trend">
              <template v-slot:header-right>
                <InfoTooltip v-if="showClrTrendTooltip" tooltip-class="clrTrendHeaderTooltip">
                  You currently have less than 12 months of trend data. Commitment Lock-In Risk is measured and
                  persisted on a daily basis and your trend history will build over time.
                </InfoTooltip>
              </template>
              <CommitmentLockInRiskTrend
                :baseline="baselineCommitmentLockInRisk"
                :trend="filteredCharts.commitmentLockInRiskTrend"
                :data-through-date="filteredCharts.dataThroughDate"
              />
            </PanelSection>
          </div>
        </div>
        <div class="row sectional cudExpirationTimelines">
          <div class="col">
            <DropdownFilter
              v-if="!isDemo"
              v-model:model-value="selectedProjectId"
              :filter-values="projectOptions"
              label="Project"
              search-place-holder="Search Projects"
              all-keys-label="All Projects"
            />
          </div>
        </div>
        <h3 class="cudExpirationTimelineHeader">Resource-based CUD Expiration Timeline</h3>
        <div class="row">
          <div class="col">
            <div
              v-for="timeline in filteredTimelines"
              :key="`${timeline.project_id}:${timeline.match_key}`"
              class="cudExpirationTimelinePanel"
            >
              <CUDExpirationTimeline :timeline="timeline" :data-through-date="filteredCharts.dataThroughDate" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

// Highcharts resizing via javascript breaks the layout with flexbox, the detail CLR detail loads fine but
// gets stuck on the second row when resizing up or down. Using css grid for full control over the columns/rows
$sidebar-and-padding: 375px;
$gauge-width: 430px;
$detail-min: 550px;

// account for the -15 margin on each side from the row class
$detail-offset: 15px * 2;

.row.summary {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $gauge-width + $detail-min + $sidebar-and-padding) {
    grid-template-columns: $gauge-width minmax($detail-min, 1fr);
  }
}

.commitmentLockInRisk {
  :deep(p) {
    max-width: 675px;
  }
}

.fadeIn {
  opacity: 1;
  animation: fade 1s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.fixedWidthToggle {
  width: 6rem;
}

.cudExpirationTimelinePanel {
  margin-bottom: 2rem;
}

.cudExpirationTimelineHeader {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.cudExpirationTimelines {
  margin-top: 2em;
}

// Because the tooltip is rendered in a portal, we can't use scoped styles
.popover.clrTrendHeaderTooltip {
  max-width: 280px;
}
</style>
