<script>
import { defineComponent } from 'vue';

import NewIndicator from '@console/components/navigation/NewIndicator.vue';
import Layout from '@console/Layout.vue';
import AwsLogoDark from '@shared/design/icons/AwsLogoDark.vue';
import AzureLogo from '@shared/design/icons/AzureLogo.vue';
import GcpLogo from '@shared/design/icons/GcpLogo.vue';

export default defineComponent({
  name: 'Start',
  components: {
    AwsLogoDark,
    AzureLogo,
    GcpLogo,
    Layout,
    NewIndicator,
  },
  head: {
    title: 'Get Started',
  },
});
</script>

<template>
  <Layout :no-nav="true" :wide="true">
    <template #default>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3 mt-4">
            <h1 class="display-4">Get Started</h1>
            <div style="margin-top: 2rem">
              <router-link
                :to="{
                  name: 'root',
                  query: { contextCloud: 'aws', contextId: 'NEW_ORGANIZATION_PLACEHOLDER' },
                }"
                class="link"
              >
                <div class="icon aws">
                  <AwsLogoDark />
                </div>
                Connect AWS Organization
              </router-link>
            </div>
            <div class="mt-4">
              <router-link
                :to="{
                  name: 'root',
                  query: { contextCloud: 'azure', contextId: 'NEW_BILLING_SCOPE_PLACEHOLDER' },
                }"
                class="link"
              >
                <div class="icon azure">
                  <AzureLogo />
                </div>
                Connect Azure Billing Scope <NewIndicator label="Early Access" class="earlyAccessBadge" />
              </router-link>
            </div>
            <div class="mt-4">
              <router-link
                :to="{
                  name: 'root',
                  query: { contextCloud: 'gcp', contextId: 'NEW_BILLING_ACCOUNT_PLACEHOLDER' },
                }"
                class="link"
              >
                <div class="icon gcp">
                  <GcpLogo />
                </div>
                Connect Google Cloud Billing Account
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.link {
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 1.5rem 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: $gray-600;
  text-decoration: none;
  background: $white;
  border: 1px solid $gray-400;
  border-radius: 0.5rem;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);

  &:active,
  &:focus,
  &:hover {
    color: $gray-800;
    border-color: $gray-500;
  }
}

.icon {
  flex-basis: 3rem;
  flex-shrink: 0;
  margin-right: 2rem;

  > svg {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.earlyAccessBadge {
  margin-left: 0.75rem;
}
</style>
