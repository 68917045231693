<script setup lang="ts">
import type { NonComputeService } from '@console/services/api.models';

import { computed, onBeforeMount } from 'vue';

import { useAwsStore } from '@aws/stores/aws.store';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import NavigationHeader from '@console/components/navigation/NavigationHeader.vue';
import NavigationLink from '@console/components/navigation/NavigationLink.vue';
import NavigationList from '@console/components/navigation/NavigationList.vue';
import NavigationSection from '@console/components/navigation/NavigationSection.vue';

const vuexStore = useVuexStore();

const isSubscribed = computed(() => vuexStore.getters['customer/isSubscribed']);
const isDemo = computed(() => vuexStore.getters['customer/isDemo']);
const isReseller = computed(() => vuexStore.getters['customer/isReseller']);
const selectedOrganizationHasOnboarded = computed(() => vuexStore.getters['aws/selectedOrganizationHasOnboarded']);

const selectedOrganizationEnabledNonComputeServices = computed(
  () => vuexStore.getters['aws/selectedOrganizationEnabledNonComputeServices']
);

const isMultiOrgSummarySelected = computed(() => vuexStore.getters['aws/isMultiOrgSummarySelected']);

const icons = {
  elasti_cache: 'aws-elasticache-icon',
  memory_db: 'aws-memorydb-icon',
  open_search: 'aws-opensearch-icon',
  rds: 'aws-rds-icon',
  redshift: 'aws-redshift-icon',
};

const getNonComputeIcon = (service: keyof typeof icons) => {
  return icons[service];
};

const nonComputeServices = computed(() => {
  const services = ['rds', 'elasti_cache', 'memory_db', 'open_search', 'redshift'] as const;

  return services.map(service => ({
    name: service,
    isEnabled: selectedOrganizationEnabledNonComputeServices.value[service],
    label: AwsServiceHelpers.getDisplayName(service),
    icon: getNonComputeIcon(service),
  }));
});

const subscribedNonComputeServices = computed(() => {
  return nonComputeServices.value.filter(service => service.isEnabled);
});

const unsubscribedNonComputeServices = computed(() => {
  return nonComputeServices.value.filter(service => !service.isEnabled);
});

const awsStore = useAwsStore();
onBeforeMount(() => {
  awsStore.loadOrganization();
});

const featureStore = useFeatureStore();
const showCallToAction = (service: NonComputeService) => {
  if (!featureStore.nonComputeCallToAction) return false;

  const orgUsage = awsStore.usage;
  if (!orgUsage) return false;

  const serviceUsage = orgUsage[`${service}_normalized_usage`];
  const esr = orgUsage[`${service}_normalized_effective_savings_rate`];

  switch (service) {
    case 'elasti_cache':
    case 'memory_db':
    case 'open_search':
    case 'rds':
      return serviceUsage > 40000 && esr < 17;
    case 'redshift':
      return serviceUsage > 10000 && esr < 40;
    default:
      return false;
  }
};
</script>

<template>
  <div>
    <template v-if="isSubscribed && selectedOrganizationHasOnboarded">
      <nav data-test="multi-product-nav">
        <NavigationHeader>Autonomous Discount Management</NavigationHeader>
        <NavigationSection icon="aws-ec2-icon" data-test="nav-section-ec2">
          <template #header>Compute</template>
          <template #items>
            <li>
              <NavigationLink icon="savings-icon" :to="{ name: 'aws_compute_savings' }" custom-icon indent compact>
                Savings
              </NavigationLink>
            </li>
            <li v-if="!isMultiOrgSummarySelected">
              <NavigationLink
                icon="commitments-icon"
                :to="{ name: 'aws_compute_commitments' }"
                custom-icon
                indent
                compact
              >
                Commitments
              </NavigationLink>
            </li>
            <li v-if="!isMultiOrgSummarySelected">
              <NavigationLink
                icon="prepayments-icon"
                :to="{ name: 'aws_compute_prepayments' }"
                custom-icon
                indent
                compact
              >
                Prepayments
              </NavigationLink>
            </li>
            <li v-if="!isMultiOrgSummarySelected">
              <NavigationLink icon="cog" :to="{ name: 'aws_compute_settings' }" indent compact>Settings</NavigationLink>
            </li>
          </template>
        </NavigationSection>

        <!-- Non-Compute Services (doesn't currently support resellers or multi-org summaries) -->
        <template v-if="!isMultiOrgSummarySelected && !isReseller">
          <NavigationSection
            v-for="service in subscribedNonComputeServices"
            :key="service.name"
            :icon="service.icon"
            :data-test="`nav-section-${service.name}`"
          >
            <template #header>
              {{ service.label }}
            </template>
            <template #items>
              <li>
                <NavigationLink
                  icon="savings-icon"
                  :to="{ name: `aws_${service.name}_savings_dashboard` }"
                  custom-icon
                  indent
                  compact
                >
                  Savings
                </NavigationLink>
              </li>
              <li>
                <NavigationLink
                  icon="commitments-icon"
                  :to="{ name: `aws_${service.name}_commitments_dashboard` }"
                  custom-icon
                  indent
                  compact
                >
                  Commitments
                </NavigationLink>
              </li>
              <li>
                <NavigationLink icon="cog" :to="{ name: `aws_${service.name}_settings` }" indent compact
                  >Settings</NavigationLink
                >
              </li>
            </template>
          </NavigationSection>

          <!-- List any unsubscribed services after the subscribed services -->
          <NavigationLink
            v-for="service in unsubscribedNonComputeServices"
            :key="service.name"
            :icon="service.icon"
            :to="{ name: `aws_${service.name}_request_savings_analysis` }"
            custom-icon
            :is-optimizable="showCallToAction(service.name)"
          >
            {{ service.label }}
          </NavigationLink>
        </template>
      </nav>

      <nav v-if="featureStore.armScheduler" class="mt-3">
        <NavigationHeader>Autonomous Resource Management</NavigationHeader>
        <NavigationSection icon="aws-ec2-icon">
          <template #header>Scheduler</template>
          <template #items>
            <li>
              <NavigationLink icon="dollar-sign" :to="{ name: 'aws_arm_scheduler_costs_avoided' }" indent compact>
                Costs Avoided
              </NavigationLink>
            </li>
            <li>
              <NavigationLink icon="layer-group" :to="{ name: 'aws_arm_scheduler_resources' }" indent compact>
                Resources
              </NavigationLink>
            </li>
          </template>
        </NavigationSection>
      </nav>

      <nav v-if="!isReseller && !isMultiOrgSummarySelected" class="mt-3">
        <NavigationHeader>Intelligent Showback</NavigationHeader>
        <NavigationList>
          <li>
            <NavigationLink icon="showback-icon" :to="{ name: 'aws_showback' }" custom-icon> Showback </NavigationLink>
          </li>
        </NavigationList>
      </nav>
      <nav v-if="!isMultiOrgSummarySelected && !isDemo" class="mt-3">
        <NavigationHeader>Configuration</NavigationHeader>
        <NavigationList>
          <li>
            <NavigationLink icon="aws-accounts-icon" :to="{ name: 'aws_accounts' }" custom-icon>
              AWS Accounts
            </NavigationLink>
          </li>
          <li v-if="featureStore.customerSettings">
            <NavigationLink
              icon="cog"
              :to="{ name: 'aws_settings' }"
              :icon-style-overrides="{ height: '24px', width: '24px' }"
            >
              Settings
            </NavigationLink>
          </li>
        </NavigationList>
      </nav>
    </template>
    <template v-else>
      <NavigationList>
        <li>
          <NavigationLink icon="onboarding-icon" :to="{ name: 'aws_onboarding' }" custom-icon>
            Onboarding
          </NavigationLink>
        </li>
      </NavigationList>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.earlyAccessBadge {
  margin-left: 0.75rem;
}
</style>
